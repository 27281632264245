import React from 'react'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import Instagram from '../../assets/img/social-media/instagram.svg'
import Facebook from '../../assets/img/social-media/facebook.svg'

import { media } from '../../styles/util'

const SocialMediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  place-items: center;
  grid-column-gap: 12px;
  margin-top: 18px;

  & a {
    width: fit-content;
    transition: 200ms all ease-in-out;
  }

  & a:hover {
    transform: scale(1.07);
  }

  ${media.mobile} {
    width: 130px;
  }
  ${media.desktop} {
    justify-content: center;
    width: 193px;
  }
`

const SocialMediaIcons = () => {
  return (
    <SocialMediaGrid>
      <OutboundLink
        aria-label="Instagram"
        href="https://www.instagram.com/grummies/"
        target="_blank"
        rel="nopoener noreferrer"
      >
        <img src={Instagram} alt="Instagram" style={{ height: 33 }} />
      </OutboundLink>
      <OutboundLink
        aria-label="Facebook"
        href="https://www.facebook.com/Grummies/"
        target="_blank"
        rel="nopoener noreferrer"
      >
        <img src={Facebook} alt="Facebook" style={{ height: 33 }} />
      </OutboundLink>
    </SocialMediaGrid>
  )
}

export default SocialMediaIcons
