import React from 'react'
import styled from 'styled-components'
import { SmallP } from '../shared/text'

import { media } from '../../styles/util'

import DayJobSVG from '../../assets/img/logos/day-job.svg'

const CopyrightFlex = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile} {
    margin: 80px 0 0 0;
  }
  ${media.tablet} {
    margin: 40px 0 0 0;
  }
`

const BoldSmallP = styled(SmallP)`
  font-family: 'Beacon-Bold';
  margin-bottom: 16px;
`

const DayJobIcon = styled.img`
  height: 14px;
  box-sizing: content-box;

  margin: 0 0 -4px 3px;
  border-bottom: 1px solid transparent;
  transition: 250ms ease all;

  &:hover {
    border-bottom: 1px solid white;
  }
`

const EmailP = styled(SmallP)`
  margin-bottom: 16px;
  &:hover {
    color: white;
  }

  ${media.desktop} {
    display: none;
  }
`

const LegalCopy = () => (
  <CopyrightFlex>
    <BoldSmallP>
      Branding by{' '}
      <a href="https://dayjob.work/" target="_blank" rel="noopener noreferrer">
        <DayJobIcon src={DayJobSVG} alt="DayJob" />
      </a>
    </BoldSmallP>

    <BoldSmallP>
      Copyright 2021 Eat Your Gummies LLC, 100 Crosby St, Suite 106, New York, NY 10012
    </BoldSmallP>

    <EmailP as="a" href="mailto:support@eatyourgrummies.com">
      Support@eatyourgrummies.com
    </EmailP>

    <SmallP>
      These statements have not been evaluated by the Food and Drug Administration. This product is
      not intended to diagnose, treat, cure, or prevent any disease.
    </SmallP>
  </CopyrightFlex>
)

export default LegalCopy
