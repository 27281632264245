import React from 'react'
import styled from 'styled-components'
import { PageRendererProps } from 'gatsby'

import DesktopFooter from './DesktopFooter'
import MobileFooter from './MobileFooter'

import { getScreenType } from '../../styles/util'

const Wrapper = styled.div`
  width: 100%;
`

interface FooterContentInterface {
  header: string
  links: {
    label: string
    path: string
    external?: boolean
  }[]
}

const Footer = ({ location }: PageRendererProps) => {
  const isMobile = getScreenType() !== 'desktop'

  const footerContent: FooterContentInterface[] = [
    {
      header: 'Grummies',
      links: [
        { label: 'Our products', path: '/products' },
        { label: `Real ingredients`, path: '/real' },
        {
          label: 'My account',
          path: 'https://shop.eatyourgrummies.com/account/login',
          external: true,
        },
      ],
    },
    {
      header: 'Information',
      links: [
        { label: `FAQs`, path: '/faq' },
        { label: `Shipping & returns`, path: '/shipping-and-returns' },
        { label: `Terms & conditions`, path: '/terms-and-conditions' },
        { label: `Privacy policy`, path: '/privacy-policy' },
      ],
    },
    {
      header: 'Contact',
      links: [
        {
          label: isMobile ? `Customer support` : `Support@eatyourgrummies.com`,
          path: 'mailto:support@eatyourgrummies.com',
          external: true,
        },
        { label: `Partnerships`, path: 'mailto:hello@eatyourgrummies.com', external: true },
        { label: `Press`, path: 'mailto:hello@eatyourgrummies.com', external: true },
        {
          label: `Careers`,
          path:
            'https://jobs.lever.co/innovationdept/5128917f-584c-4574-9446-b51309001876?lever-origin=applied&lever-source%5B%5D=Grummies%20Website',
          external: true,
        },
      ],
    },
  ]

  const hideRotatingGummy = ['our-story']
  const hideGummy = hideRotatingGummy.some(page => location.pathname.includes(page))

  return (
    <Wrapper>
      <DesktopFooter footerContent={footerContent} hideGummy={hideGummy} />
      <MobileFooter footerContent={footerContent} hideGummy={hideGummy} />
    </Wrapper>
  )
}

export default Footer
