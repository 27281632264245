import React from 'react'
import styled, { keyframes } from 'styled-components'

import { media } from '../../styles/util'

import GummyBadge from '../../assets/img/icons/gummy-badge.png'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`

const GummyImg = styled.img`
  animation: ${rotateAnimation} 20s linear infinite;
  margin: 0 auto;
  ${media.mobile} {
    height: 100px;
    width: 100px;
  }
  ${media.tablet} {
    height: 185px;
    width: 185px;
  }
`

export default () => <GummyImg src={GummyBadge} alt="have you eaten your grummies today" />
