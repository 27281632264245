import React, { useState, useContext, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { LineItem } from 'shopify-buy'

import CartDrawer from './CartDrawer'
import NavDrawer from './NavDrawer'
import { SmallP } from '../shared/text'
import { media } from '../../styles/util'

import MenuButtonImg from '../../assets/img/navbar/menu-button.svg'
import MobileMenuButtonImg from '../../assets/img/navbar/menu-button-mobile.svg'
import CartButton from '../../assets/img/navbar/cart-button.svg'

import StoreContext from '../../context/StoreContext'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const AbsoluteImg = styled.img`
  position: fixed;
`

const MenuButton = styled(AbsoluteImg)<{ menuOpen: boolean }>`
  width: 61px;
  cursor: pointer;
  transition: 150ms all ease;
  z-index: 1000;
  top: 0;
  left: 0;

  &:hover {
    transform: scale(1.1) rotate(6deg) translate(-5px, 0px);
  }

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    align-self: start;
    transform: ${props =>
      props.menuOpen ? 'scale(1.1) rotate(6deg) translate(-5px, 0px)' : 'none'};
  }
`

const MobileMenuButton = styled(AbsoluteImg)`
  width: 48px;
  cursor: pointer;
  align-self: start;
  z-index: 1000;
  top: 0;
  left: 0;

  ${media.tablet} {
    display: none;
  }
`

const CartButtonWrapper = styled.div`
  position: fixed;

  cursor: pointer;
  transition: 250ms all ease;
  align-self: center;
  z-index: 1000;
  height: 50px;
  width: 50px;

  ${media.mobile} {
    top: 16px;
    right: 13px;
  }
  ${media.tablet} {
    top: 22px;
    right: 20px;

    &:hover {
      transform: scale(1.06);
      animation: ${rotateAnimation} 250ms ease;
    }
  }
`

const RelativeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const CartButtonImg = styled(AbsoluteImg)`
  object-fit: contain;
`

const ItemsCount = styled(SmallP)`
  font-family: Beacon-Bold, sans-serif;
  font-size: 10px;
  transform: translate(-50%, -60%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
`

const NavBar = () => (
  <StaticQuery
    query={graphql`
      query {
        logoImg: file(relativePath: { regex: "/logo-navy.png/" }) {
          childImageSharp {
            fluid(maxWidth: 105) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={() => {
      const { cartMenuOpen, setCartMenuOpen, store } = useContext(StoreContext)
      const [navDrawerOpen, setNavDrawerOpen] = useState(false)

      const [cartCount, setCartCount] = useState(0)

      const cartUpdating = store && store.updating

      useEffect(() => {
        const counterCart = store.cart
        if (!cartUpdating && Boolean(counterCart)) {
          const lineItemCount = counterCart.lineItems.reduce(
            (count: number, lineItem: LineItem) => {
              const newCount = count + lineItem.quantity
              return newCount
            },
            0
          )

          setCartCount(lineItemCount)
        }
      }, [cartUpdating])

      const openNavMenu = () => setNavDrawerOpen(true)
      const closeNavMenu = () => setNavDrawerOpen(false)

      const openCartMenu = () => setCartMenuOpen(true)
      const closeCartMenu = () => setCartMenuOpen(false)

      return (
        <div>
          <MenuButton
            src={MenuButtonImg}
            alt="Menu button"
            onClick={openNavMenu}
            menuOpen={navDrawerOpen}
          />
          <MobileMenuButton src={MobileMenuButtonImg} alt="Menu button" onClick={openNavMenu} />
          <CartButtonWrapper onClick={openCartMenu}>
            <RelativeContainer>
              <CartButtonImg src={CartButton} alt="Cart button" />
              {cartCount > 0 && <ItemsCount>{cartCount}</ItemsCount>}
            </RelativeContainer>
          </CartButtonWrapper>
          <NavDrawer menuOpen={navDrawerOpen} closeMenu={closeNavMenu} />
          <CartDrawer menuOpen={cartMenuOpen} closeMenu={closeCartMenu} />
        </div>
      )
    }}
  />
)

export default NavBar
