import React, { useState, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import { LineItem, ProductVariant } from 'shopify-buy/index.unoptimized.umd'
import Img from 'gatsby-image'
import { navigate, useStaticQuery, graphql } from 'gatsby'

import getCheckoutUrl from 'src/utils/getCheckoutUrl'
import CartRow from './CartRow'
import UpsellBox from './UpsellBox'
import Spinner from '../../shared/Spinner'
import { CtaButton } from '../../shared/buttons'
import { H2, P } from '../../shared/text'
import { media } from '../../../styles/util'
import getProductAttributes from '../../../utils/getProductAttributes'

import StoreContext from '../../../context/StoreContext'

import CloseIcon from '../../../assets/img/icons/cart-close.svg'

const PurpleWrapper = styled('div')`
  position: relative;
  height: 100vh;
  background-color: ${props => props.theme.colors.purple};
  overflow-y: hidden;

  ${media.mobile} {
    width: 100vw;
    padding: 35px 24px 40px 24px;
  }
  ${media.tablet} {
    width: 500px;
    padding: 35px 34px 40px 45px;
  }
`

const Menu = styled('div')<{ emptyCart: boolean; showUpsell: boolean }>`
  color: white;
  height: 100%;
  display: grid;
  align-items: start;
  overflow-y: hidden;

  grid-template-rows: ${props =>
    props.emptyCart
      ? '90px auto'
      : `90px ${props.showUpsell ? 'auto' : '1fr'} ${props.showUpsell ? '1fr' : 'auto'} auto auto`};
`

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    font-size: 34px;
    line-height: 36px;
  }

  & img {
    cursor: pointer;
    height: 33px;
    width: 33px;
    transition: 200ms ease transform;
    margin-right: 6px;

    &:hover {
      transform: scale(1.1);
    }
  }
`

const CheckoutCta = styled(CtaButton)<{ clickedCheckout?: boolean }>`
  background-color: white;
  border-color: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.purple};
  padding: 17px;
  margin: 27px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: white;
    border-color: white;
  }

  & h2 {
    line-height: 1;
  }

  ${media.mobile} {
    min-width: 244px;

    & h2 {
      font-size: 25px;
    }
  }
  ${media.desktop} {
    min-width: 320px;
    height: 70px;

    & h2 {
      font-size: 34px;
    }
  }
`

const FlexRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  align-items: center;
  margin-bottom: 13px;

  &:first-of-type {
    align-items: start;
    & img {
      height: ;
    }
  }
`

const LineItemsContainer = styled.div`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.lightPurple};
    border-radius: 100px;
  }
`

const SubtotalRow = styled(FlexRow)`
  margin: 27px 0 0 0;
  border-top: 2px solid white;

  ${media.mobile} {
    padding: 10px 0 9px 0;
    width: 98%;

    & h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
  ${media.desktop} {
    padding: 10px 0 12px 0;
    width: 97%;

    & h2 {
      font-size: 25px;
      line-height: 30px;
    }
  }
`

const LightPurpleText = styled.span`
  color: ${props => props.theme.colors.lightPurple};
`

const StruckText = styled(LightPurpleText)`
  text-decoration: line-through;
  margin-right: 12px;
`

const PackSavingsRow = styled(SubtotalRow)`
  border: none;
  margin: 0;

  ${media.mobile} {
    padding: 0 0 9px 0;
  }
`

const ButtonArrow = styled.p`
  font-family: Helvetica, sans-serif;
  margin: 0 0 -3px 6px;
  display: inline-block;
`

const ShippingText = styled(P)`
  width: fit-content;
  font-size: 14px;
  font-family: Beacon-Bold, sans-serif;
  background: linear-gradient(90deg, #fa6666 0%, #f090c3 39.58%, #52d9bf 73.44%, #21a0f8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const EmptyContainer = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > h2 {
    font-size: 34px;
    line-height: 40px;
    max-width: 309px;
    margin-bottom: 34px;
  }
`

const floatAnimation = keyframes`
  0% { transform: translate(-50%,  0px); }
  50%  { transform: translate(-50%, 20px); }
  100%  { transform: translate(-50%, 0px); }
`

const BottomImage = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  animation: ${floatAnimation} 15s infinite;

  ${media.mobile} {
    bottom: -150px;
  }
  ${media.desktop} {
    bottom: -200px;
  }
`

const query = graphql`
  query {
    blobs: file(relativePath: { regex: "/navbar/blobs.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface CartDrawerInterface {
  menuOpen: boolean
  closeMenu: () => void
}

interface ExtendedProductVariant extends ProductVariant {
  sku: string
}

interface AttributeInterface {
  attrs: {
    key: {
      value: string
    }
    value: {
      value: string
    }
  }
}

interface ExtendedLineItem extends LineItem {
  variant: ExtendedProductVariant
  customAttributes: AttributeInterface[]
}

const CartDrawer = ({ menuOpen, closeMenu }: CartDrawerInterface) => {
  const { store, products } = useContext(StoreContext)
  const { blobs } = useStaticQuery(query)

  const [clickedCheckout, setClickedCheckout] = useState(false)

  const subtotal = (store && store.cart && store.cart.subtotalPrice) || 0

  const lineItems = (store && store.cart && store.cart.lineItems) || []
  const numberOfItems = lineItems.reduce((total: number, lineItem: ExtendedLineItem) => {
    return total + lineItem.quantity
  }, 0)

  let twoPackCount = 0
  let threePackCount = 0
  let fourPackCount = 0
  let includesBundle = false
  let multipackSavings = 0
  let subscriptionSavings = 0

  lineItems.forEach((lineItem: ExtendedLineItem) => {
    const productLookupKey = products.byVariantId[lineItem.variant.id].basicSku
    const lineItemDetail = products.byProduct[productLookupKey]
    const isBundle = lineItem.variant.sku.includes('BUNDLE')

    const attrs = getProductAttributes(lineItem.customAttributes)

    if (attrs.selling_plan) {
      subscriptionSavings += Number(attrs.selling_plan_price_discount) * lineItem.quantity
    }

    if (!isBundle) {
      if (lineItem.variant.sku.includes('2PK')) {
        twoPackCount++
        const { price, compareAtPrice } = lineItemDetail.multipack_2
        multipackSavings += (compareAtPrice - price) * lineItem.quantity
      }
      if (!isBundle && lineItem.variant.sku.includes('3PK')) {
        threePackCount++
        const { price, compareAtPrice } = lineItemDetail.multipack_3
        multipackSavings += (compareAtPrice - price) * lineItem.quantity
      }
      if (!isBundle && lineItem.variant.sku.includes('4PK')) {
        fourPackCount++
        const { price, compareAtPrice } = lineItemDetail.multipack_4
        multipackSavings += (compareAtPrice - price) * lineItem.quantity
      }
    } else {
      includesBundle = true
      if (!lineItem.variant.sku.includes('2PK')) {
        const { price, compareAtPrice } = lineItemDetail.single
        multipackSavings += (compareAtPrice - price) * lineItem.quantity
      } else {
        const { price, compareAtPrice } = lineItemDetail.multipack_2
        multipackSavings += (compareAtPrice - price) * lineItem.quantity
      }
    }
  })

  const containsMultipack = !!twoPackCount || !!threePackCount || !!fourPackCount

  const shipsFree = containsMultipack || includesBundle || Number(subtotal) > 30

  let showUpsell = false
  if (numberOfItems === 1 && !threePackCount && !fourPackCount && !subscriptionSavings) {
    showUpsell = true
  }

  const handleCtaClick = async () => {
    setClickedCheckout(true)
    const checkoutUrl = getCheckoutUrl(store.cart)
    window.location.href = checkoutUrl
  }

  const totalSavings = multipackSavings + subscriptionSavings

  return (
    <Drawer anchor="right" open={menuOpen} onClose={closeMenu}>
      <PurpleWrapper>
        <Menu emptyCart={!numberOfItems} showUpsell={showUpsell}>
          <HeaderRow>
            <H2>My Cart</H2>
            <img src={CloseIcon} alt="Close icon" onClick={closeMenu} role="button" />
          </HeaderRow>
          {(store.updating || clickedCheckout) && (
            <Spinner overlay={props => props.theme.colors.purple} color="white" opacity={1} />
          )}
          {numberOfItems > 0 ? (
            <>
              <LineItemsContainer>
                {store.cart.lineItems.map((lineItem: ExtendedLineItem) => (
                  <CartRow
                    lineItem={lineItem}
                    includesBundle={includesBundle}
                    twoPackCount={twoPackCount}
                    fourPackCount={fourPackCount}
                    numberOfItems={numberOfItems}
                    closeMenu={closeMenu}
                    key={lineItem.id}
                  />
                ))}
              </LineItemsContainer>

              {showUpsell ? <UpsellBox /> : <div />}

              <div>
                <SubtotalRow>
                  <H2>Subtotal:</H2>
                  <H2>
                    {totalSavings > 0 && (
                      <StruckText>${(Number(subtotal) + multipackSavings).toFixed(2)}</StruckText>
                    )}
                    ${Number(subtotal - subscriptionSavings).toFixed(2)}
                  </H2>
                </SubtotalRow>
                {totalSavings > 0 && (
                  <PackSavingsRow>
                    <H2>Savings:</H2>
                    <H2>
                      <LightPurpleText>${totalSavings.toFixed(2)}</LightPurpleText>
                    </H2>
                  </PackSavingsRow>
                )}
                {shipsFree && <ShippingText>This order ships free!</ShippingText>}
              </div>

              <CheckoutCta onClick={handleCtaClick}>
                <H2>Check out</H2>
                <ButtonArrow> →</ButtonArrow>
              </CheckoutCta>
            </>
          ) : (
            <EmptyContainer>
              <H2>This cart is empty. Get yourself some Grummies.</H2>
              <CheckoutCta
                onClick={() => {
                  closeMenu()
                  navigate('/products')
                }}
              >
                <H2>Shop now</H2>
                <ButtonArrow> →</ButtonArrow>
              </CheckoutCta>
              <BottomImage>
                <Img
                  fluid={blobs.childImageSharp.fluid}
                  aria-hidden="true"
                  style={{ width: '100%', height: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </BottomImage>
            </EmptyContainer>
          )}
        </Menu>
      </PurpleWrapper>
    </Drawer>
  )
}

export default CartDrawer
