import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    background-color: #fff;
    color: #662D8C;
    font-family:  Beacon, Helvetica, sans-serif;

    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    padding: 0;

    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  main,
  section,
  div {
    margin: 0;
    padding: 0;
    max-width: 100vw;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
    &:focus {
      color: inherit;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Cooper, serif;
  }

  .MuiPaper-rounded {
    border-radius: 10px !important;
  }

  .MuiPaper-root {
    border: 1px solid #662D8C;

    .MuiListItem-root {
      min-width: 170px;
      font-family: Beacon, sans-serif;
      font-size: 15px;
      color: #662D8C;
      min-height: 36px;

      &:hover {
        color: #662D8C;
        font-family: Beacon-Bold, sans-serif;
      }
    }

    .Mui-selected {
      color: #662D8C;
      font-family: Beacon-Bold, sans-serif;
    }
  }
`

export default GlobalStyle
