import React from 'react'
import styled, { keyframes } from 'styled-components'
import Helmet from 'react-helmet'
import { PageRendererProps } from 'gatsby'

import ErrorBoundary from '../components/shared/ErrorBoundary'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

import GlobalStyle from './GlobalStyle'

import favicon from '../assets/img/logos/favicon.png'

const fadeInAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const MainWrapper = styled.div`
  animation: ${fadeInAnimation} 1s ease;
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 1fr auto;
`

const IndexLayout: React.FC<PageRendererProps> = ({ children, location }) => {
  const isValidWindow = typeof window !== 'undefined'
  const isOrganicTraffic = isValidWindow && document.referrer.includes('google.com')
  const isSpirulinaPDP = isValidWindow && window.location.pathname.includes('spirulina')
  if (isOrganicTraffic && isSpirulinaPDP) window.location.replace('/')

  return (
    <>
      <GlobalStyle />
      <ErrorBoundary errorText="Sorry something went wrong">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          link={[{ rel: 'icon', type: 'image/png', href: favicon }]}
        />
        <NavBar />
        <MainWrapper>
          {children}
          <Footer location={location} />
        </MainWrapper>
      </ErrorBoundary>
    </>
  )
}

export default IndexLayout
