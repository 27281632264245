interface AttributeInterface {
  attrs: {
    key: {
      value: string
    }
    value: {
      value: string
    }
  }
}

interface MapInterface {
  [key: string]: string
}

export default (customAttributes: Array<AttributeInterface>) => {
  const attributeMap = customAttributes.reduce((map: MapInterface, customAttribute: AttributeInterface) => {
    map[customAttribute.attrs.key.value] = customAttribute.attrs.value.value
    return map
  }, {})
  return attributeMap
}
