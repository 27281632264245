import styled from 'styled-components'
import { media } from '../../styles/util'

export const H1 = styled.h1`
  ${media.mobile} {
    font-size: 34px;
    line-height: 36px;
  }
  ${media.tablet} {
    font-size: 50px;
    line-height: 60px;
  }
  ${media.desktop} {
    font-size: 60px;
    line-height: 70px;
  }
`

export const H2 = styled.h2`
  font-size: 25px;
  line-height: 33px;
`

export const H3 = styled.h3`
  font-size: 16px;
  line-height: 19px;
`

export const LargeP = styled.p`
  ${media.mobile} {
    font-size: 16px;
    line-height: 20px;
  }
  ${media.tablet} {
    font-size: 24px;
    line-height: 32px;
  }
`

export const P = styled.p`
  font-size: 16px;
  line-height: 24px;
`

export const SmallP = styled.p`
  font-size: 12px;
  line-height: 16px;
`
