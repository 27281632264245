import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import qs from 'qs'

import { P, SmallP } from '../shared/text'

import { media } from '../../styles/util'

const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 5px 33px 20px;
  grid-row-gap: 12px;

  ${media.mobile} {
    max-width: 80vw;
  }
  ${media.tablet} {
    max-width: 270px;
  }
`

const Input = styled.input`
  width: 100%;
  font-family: Cooper, serif;
  font-size: 16px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;

  &::placeholder {
    color: #fff;
  }
`

const StyledSubmit = styled.button`
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: #fff;
  border: 1px solid white;
  font-family: Beacon, sans-serif !important;
  font-size: 15px !important;
  line-height: 1;
  border-radius: 10px;
  height: fit-content;
  width: fit-content;
  margin-top: 10px;

  &:hover {
    background-color: white;
    color: ${props => props.theme.colors.purple};
  }

  ${media.mobile} {
    padding: 6px 20px 6px 20px;
  }
  ${media.tablet} {
    padding: 2px 20px 4px 20px;
  }
`

const BottomLine = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 25px;
  background: linear-gradient(
    270deg,
    #fa6666 -4.55%,
    #52d9bf -4.55%,
    #21a0f8 43.37%,
    #fa6666 91.81%
  );
`

const SuccessText = styled(P)`
  font-family: Cooper, serif;
  font-size: 16px !important;
  padding-bottom: 12px;
  color: ${props => props.theme.colors.blue} !important;
`

const ErrorText = styled(SmallP)`
  color: ${props => props.theme.colors.orange} !important;
  font-family: Beacon-Bold, sans-serif;
  padding-top: 6px;
`

const EmailForm = () => {
  const { register, handleSubmit, reset } = useForm()
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = async (data: { [key: string]: string }) => {
    setError('')

    const listId = 'XBffHd' // Lists & Segments > Newsletter

    const klaviyoData = {
      g: listId,
      $fields: '$source',
      ...data,
      $source: 'OnSiteFooter',
    }

    const stringifiedData = qs.stringify(klaviyoData)
    const response = await axios({
      method: 'post',
      url: '//manage.kmail-lists.com/ajax/subscriptions/subscribe',
      data: stringifiedData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    }).catch(err => {
      console.log(err)
      setError('Uh oh, sign up failed. Please try again.')
    })

    if (response && response.data && response.data.success) {
      reset()
      setSubmitted(true)
    } else {
      setError('Uh oh, something went wrong. Please try again.')
    }
  }

  return (
    <Form key="email" onSubmit={handleSubmit(onSubmit)}>
      {!submitted ? (
        <Input
          name="email"
          type="email"
          ref={register({
            required: true,
          })}
          placeholder="Email Address"
          aria-label="Email address"
        />
      ) : (
        <SuccessText>You're signed up!</SuccessText>
      )}
      <BottomLine />
      {!submitted ? <StyledSubmit type="submit">Sign up</StyledSubmit> : ''}
      {error ? <ErrorText>{error}</ErrorText> : ''}
    </Form>
  )
}

export default EmailForm
