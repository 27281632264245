import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { H3 } from '../shared/text'
import { media } from '../../styles/util'

const ColumnGrid = styled.div`
  height: fit-content;
  display: grid;

  ${media.mobile} {
    grid-row-gap: 9px;
  }
  ${media.tablet} {
    grid-row-gap: 6px;
  }

  & h3 {
    margin-bottom: 9px;
  }

  & a {
    line-height: 1.3;
  }
`

interface LinksInterface {
  label: string
  path: string
  external?: boolean
}

interface FooterContentInterface {
  header: string
  links: LinksInterface[]
}

const LinkColumnGroup = ({ header, links }: FooterContentInterface) => (
  <ColumnGrid key={header}>
    <H3>{header}</H3>
    {links.map((link: LinksInterface) =>
      link.external ? (
        <a href={link.path} target="_blank" rel="noopener noreferrer" key={link.label}>
          {link.label}
        </a>
      ) : (
        <Link to={link.path} key={link.label}>
          {link.label}
        </Link>
      )
    )}
  </ColumnGrid>
)

export default LinkColumnGroup
