import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { H3, P } from '../../shared/text'
import { media } from '../../../styles/util'

import StoreContext from '../../../context/StoreContext'

const GradientWrapper = styled.div`
  background: linear-gradient(
    90deg,
    #fa6666 11.69%,
    #f090c3 28.66%,
    #4bd1c7 62.14%,
    #21a0f8 91.84%
  );
  padding: 3px;
  border-radius: 10px;
  transition: 200ms all ease-out;
  width: fit-content;
  margin: 50px 0 0 0;

  &:hover {
    transform: translate(3px, -3px);
  }
`

const UpsellContainer = styled.div`
  background-color: ${props => props.theme.colors.purple};
  width: fit-content;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 60px auto;
  cursor: pointer;
  line-height: 1;

  ${media.mobile} {
    padding: 10px 20px 10px 20px;
    grid-column-gap: 15px;
  }
  ${media.desktop} {
    padding: 10px 25px 10px 20px;
    grid-column-gap: 30px;
  }

  & > div {
    display: grid;
    grid-row-gap: 6px;
  }
`

const BoldText = styled(P)`
  font-family: Beacon-Bold, sans-serif;
  line-height: 1;
  font-size: 14px;
`

const FlexRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
`

const Price = styled(BoldText)`
  padding-bottom: 3px;
`

const StyledQuickAdd = styled(Price)`
  text-decoration: underline;
  width: fit-content;

  ${media.mobile} {
    display: block;
  }
  ${media.desktop} {
    display: block;
  }

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.orange};
  }
`

const CopyContainer = styled.div`
  width: fit-content;
`

const UpsellBox = () => {
  const { loading, store, lookupProducts, addVariantToCart } = useContext(StoreContext)

  const initialProductDetail: any = {
    oneTime: { single: { price: 0, variantId: '' } },
    jsonData: {
      title: null,
      images: {},
    },
  }
  const [ashwagandhaDetail, setAshwagandhaDetail] = useState(initialProductDetail)
  const [turmericDetail, setTurmericDetail] = useState(initialProductDetail)
  const [acvDetail, setAcvDetail] = useState(initialProductDetail)

  useEffect(() => {
    if (!loading) {
      const { ASHWAGANDHA, TURMERIC, ACV } = lookupProducts(['ASHWAGANDHA', 'TURMERIC', 'ACV'])
      setAshwagandhaDetail(ASHWAGANDHA)
      setTurmericDetail(TURMERIC)
      setAcvDetail(ACV)
    }
  }, [loading])

  const lineItems = (store && store.cart && store.cart.lineItems) || []

  if (lineItems.length !== 1) return null

  let upsellProduct = ashwagandhaDetail

  const lineItemIsAshwagandha = lineItems[0].variant.sku.includes('ASHWAGANDHA')
  const lineItemIsAdaptogenBundle = lineItems[0].variant.sku.includes('ADAPTOGENBUNDLE')

  if (lineItemIsAshwagandha && !!turmericDetail) upsellProduct = turmericDetail
  if (lineItemIsAdaptogenBundle && !!acvDetail) upsellProduct = acvDetail

  const handleAddToCart = () => {
    addVariantToCart(upsellProduct.single.variantId, 1)
  }

  return !loading && upsellProduct && upsellProduct.jsonData.title ? (
    <GradientWrapper onClick={handleAddToCart}>
      <UpsellContainer>
        {upsellProduct && upsellProduct.jsonData && upsellProduct.jsonData.images && (
          <Img
            fluid={upsellProduct.jsonData.images.bottles.single.childImageSharp.fluid}
            alt="Bottle image"
            imgStyle={{ objectFit: 'contain' }}
          />
        )}
        <CopyContainer>
          <BoldText>Recommended for you</BoldText>
          <FlexRow>
            <H3>
              {upsellProduct.jsonData.title}
              <br />
              (1 pack)
            </H3>
          </FlexRow>
          <FlexRow>
            <StyledQuickAdd>Quick Add</StyledQuickAdd>
            <Price>${upsellProduct.single.pricing.oneTime.price.toFixed(0)}</Price>
          </FlexRow>
        </CopyContainer>
      </UpsellContainer>
    </GradientWrapper>
  ) : null
}

export default UpsellBox
