import styled from 'styled-components'
import { media } from '../../styles/util'

export const Button = styled.button`
  position: relative;
  cursor: pointer;
  font-family: Beacon, sans-serif;
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.purple};
  border-radius: 10px;
  transition: 200ms all ease;
  padding: 3px 16px 4px 16px;
  line-height: 1;
  width: fit-content;
  height: fit-content;
  font-size: 15px;
  outline: none;
`

export const BasicButton = styled(Button)`
  background-color: transparent;
  color: ${props => props.theme.colors.purple};

  &:hover {
    background-color: ${props => props.theme.colors.purple};
    color: #fff;
  }
`

export const FilledButton = styled(Button)`
  background-color: ${props => props.theme.colors.purple};
  color: #fff;

  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.purple};
  }
`

export const CtaButton = styled(Button)`
  font-family: Cooper, serif;
  background-color: ${props => props.theme.colors.orange};
  border: 1px solid ${props => props.theme.colors.orange};
  border-radius: 20px;
  color: #fff;
  padding: 10px 16px;

  ${media.mobile} {
    font-size: 20px;
  }
  ${media.tablet} {
    font-size: 25px;
  }

  &:hover {
    background-color: transparent;
    color: ${props => props.theme.colors.orange};
  }
`

export const PdpButton = styled(Button)<{ adding?: boolean }>`
  background-color: ${props => props.theme.colors.purple};
  border: 2px solid ${props => props.theme.colors.purple};
  border-radius: 20px;
  color: #fff;

  ${media.mobile} {
    min-width: 264px;
    height: 53px;
    padding: 0 24px;

    & h2 {
      font-size: 18px;
      line-height: 1;
    }
  }
  ${media.tablet} {
    min-width: 330px;
    height: 66px;
    padding: 0 20px;

    & h2 {
      font-size: 25px !important;
      line-height: 1;
    }
  }

  &:hover {
    background-color: ${props => (props.adding ? props.theme.colors.purple : 'transparent')};
    color: ${props => props.theme.colors.purple};
  }

  & h2 {
    font-size: 25px;
  }
`
