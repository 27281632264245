import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import EmailForm from './EmailForm'
import LegalCopy from './LegalCopy'
import LinkColumnGroup from './LinkColumnGroup'
import SocialMediaIcons from './SocialMediaIcons'
import RotatingGummy from '../shared/RotatingGummy'

import { media } from '../../styles/util'

import BackgroundImage from '../../assets/img/footer/background-desktop.svg'
import FollowUsDesktop from '../../assets/img/text-headers/follow-us.svg'

const FooterWrapper = styled.footer`
  flex-direction: column;
  justify-content: center;
  z-index: 1000;

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: flex;
  }
`

const Container = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  min-height: 471px;

  & p,
  a {
    font-size: 12px;
    color: #fff;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  ${media.tablet} {
    padding: 120px 40px 60px 40px;
  }
  ${media.desktop} {
    padding: 120px 160px 60px 160px;
  }
`

const LinksGrid = styled.div`
  width: 100%;
  min-height: 240px;
  color: #fff;
  display: grid;

  ${media.tablet} {
    grid-template-columns: repeat(3, 1fr) auto;
    grid-column-gap: 40px;
  }
  ${media.desktop} {
    grid-template-columns: repeat(3, max-content) 1fr;
    grid-column-gap: 100px;
  }
`

const Flexbox = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
`

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const LogoWrapper = styled(Link)`
  &:hover {
    animation: ${wiggle} 250ms;
  }
  ${media.tablet} {
    height: 121px;
    width: 126px;
    margin: -60px 0 0 -30px;
  }
  ${media.desktop} {
    height: 121px;
    width: 126px;
    margin: -100px 0 0 -30px;
  }
`

const ColumnGrid = styled.div`
  height: fit-content;
  display: grid;
  grid-row-gap: 4px;
  justify-self: end;

  & h3 {
    margin-bottom: 9px;
  }
`

const SocialIconsWrapper = styled.div`
  width: 100%;
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: flex;
    margin-bottom: 40px;
  }
`

interface GatsbyImgInterface {
  childImageSharp: {
    fluid: {
      aspectRatio: number
      sizes: string
      src: string
      srcSet: string
    }
  }
}

interface StaticQueryProps {
  backgroundDesktop: GatsbyImgInterface
  logo: GatsbyImgInterface
}

interface LinksInterface {
  label: string
  path: string
  external?: boolean
}

interface FooterContentInterface {
  header: string
  links: LinksInterface[]
}

const DesktopFooter = ({
  footerContent,
  hideGummy,
}: {
  footerContent: FooterContentInterface[]
  hideGummy: boolean
}) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { regex: "/logo-white.png/" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <FooterWrapper>
        {!hideGummy && <RotatingGummy />}
        <br />
        <Container>
          <Content>
            <LinksGrid>
              {footerContent.map((section: FooterContentInterface) => (
                <LinkColumnGroup
                  header={section.header}
                  links={section.links}
                  key={section.header}
                />
              ))}
              <ColumnGrid>
                <img src={FollowUsDesktop} alt="Follow us" />
                <SocialIconsWrapper>
                  <SocialMediaIcons />
                </SocialIconsWrapper>
                <EmailForm />
              </ColumnGrid>
            </LinksGrid>

            <Flexbox>
              <LogoWrapper to="/">
                <Img
                  fluid={data.logo.childImageSharp.fluid}
                  alt="Grummies logo"
                  style={{ height: '100%', width: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </LogoWrapper>
            </Flexbox>

            <LegalCopy />
          </Content>
        </Container>
      </FooterWrapper>
    )}
  />
)

export default DesktopFooter
