import React from 'react'
import styled from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'

const SpinnerContainer = styled('div')<{
  overlay?: string | ((props: any) => string)
  spinnerColor?: string | ((props: any) => string)
  opacity?: number
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-items: ${props => (props.overlay ? 'start' : 'center')};
  padding-top: ${props => (props.overlay ? '300px' : 0)};
  margin: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background: ${props => props.overlay || 'none'};
  opacity: ${props => (props.overlay && props.opacity ? props.opacity : '0.8')};
  z-index: 10;
  * {
    color: ${props => props.spinnerColor || '#000'};
  }
`

interface SpinnerProps {
  color?: string | ((props: any) => string)
  overlay?: string | ((props: any) => string)
  size?: number
  opacity?: number
}

const Spinner: React.FC<SpinnerProps> = ({ size = 60, color = '#000', overlay, opacity }) => {
  return (
    <SpinnerContainer overlay={overlay} spinnerColor={color} opacity={opacity}>
      <CircularProgress size={size} thickness={5} />
    </SpinnerContainer>
  )
}

export default Spinner
