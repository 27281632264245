import * as React from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'

const ErrorDiv = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ErrorText = styled.h6`
  text-align: center;
`

const generateErrorMessage = (errorText: string) => () => (
  <ErrorDiv>
    <ErrorText>{errorText}</ErrorText>
  </ErrorDiv>
)

const ErrorBoundary: React.FC<{ errorText: string }> = ({ children, errorText }) => {
  return (
    <Sentry.ErrorBoundary fallback={generateErrorMessage(errorText)} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
