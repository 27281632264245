interface MediaInterface {
  desktop: string
  tablet: string
  mobile: string
  custom: (a: number) => string
}

const sizes = {
  desktop: 1119,
  tablet: 767,
  mobile: 0
}

const customMediaQuery = (maxWidth: number) => `@media (min-width: ${maxWidth}px)`

// iterate through the sizes and create a media template
export const media: MediaInterface = {
  custom: (maxWidth: number) => customMediaQuery(maxWidth),
  desktop: customMediaQuery(sizes.desktop),
  tablet: customMediaQuery(sizes.tablet),
  mobile: customMediaQuery(0)
}

export const getScreenType = (): string => {
  const windowAvailable: boolean = typeof window !== 'undefined'

  let mediaType = 'desktop'

  if (windowAvailable) {
    const screenWidth: number = window.innerWidth
    if (screenWidth < sizes.desktop) mediaType = 'tablet'
    if (screenWidth < sizes.tablet) mediaType = 'mobile'
  }

  return mediaType
}
