import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import Drawer from '@material-ui/core/Drawer'

import { H2, LargeP } from '../shared/text'
import { media } from '../../styles/util'

import CloseX from '../../assets/img/icons/menu-close.svg'
import Bullet from '../../assets/img/navbar/menu-bullet.svg'
import SubBullet from '../../assets/img/navbar/menu-bullet-white.svg'
import BottomImg from '../../assets/img/navbar/menu-bottom.svg'

const Menu = styled.div`
  position: relative;

  background-color: ${props => props.theme.colors.purple};
  height: 100vh;
  padding: 42px 12px 0 28px;
  color: white;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 80px;
  overflow: hidden;

  ${media.mobile} {
    width: 100vw;
  }
  ${media.tablet} {
    width: 400px;
  }

  & h2 {
    font-size: 34px;
    line-height: 36px;
  }
`

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    padding-left: 6px;
  }

  & img {
    cursor: pointer;
    height: 22px;
    width: 22px;
    margin-right: 20px;
  }
`

const BulletImg = styled.img`
  height: 20px;
  width: auto;
  justify-self: center;
`

const SubBulletImg = styled.img`
  height: 15px;
  width: auto;
  justify-self: center;
`

const BulletRow = styled(Link)`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 9px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
`

const SubBulletRow = styled(Link)`
  display: grid;
  grid-template-columns: 15px 1fr;
  grid-column-gap: 9px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 0 2.5px;

  &:first-of-type {
    margin-top: 66px;
  }
`

const floatAnimation = keyframes`
  0% { transform: translate(-50%,  0px); }
  50%  { transform: translate(-50%, 20px); }
  100%  { transform: translate(-50%, 0px); }
`

const BottomImage = styled.img`
  width: 324px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  animation: ${floatAnimation} 15s infinite;
`

interface NavDrawerInterface {
  menuOpen: boolean
  closeMenu: () => void
}

const NavDrawer = ({ menuOpen, closeMenu }: NavDrawerInterface) => {
  const [hideImg, setHideImg] = useState(false)
  const viewportHeight = typeof window !== 'undefined' && window.innerHeight

  useEffect(() => {
    if (viewportHeight < 730) setHideImg(true)
    else setHideImg(false)
  }, [viewportHeight])

  return (
    <Drawer anchor="left" open={menuOpen} onClose={closeMenu}>
      <Menu>
        <HeaderRow>
          <H2>Menu</H2>
          <img src={CloseX} alt="Close icon" onClick={closeMenu} role="button" />
        </HeaderRow>
        <div>
          <div>
            <BulletRow to="/" onClick={closeMenu}>
              <BulletImg src={Bullet} aria-hidden /> <H2>Home</H2>
            </BulletRow>
            <BulletRow to="/products" onClick={closeMenu}>
              <BulletImg src={Bullet} aria-hidden /> <H2>Shop all Grummies</H2>
            </BulletRow>
            <BulletRow to="/real" onClick={closeMenu}>
              <BulletImg src={Bullet} aria-hidden /> <H2>Real ingredients</H2>
            </BulletRow>
          </div>
          <div>
            <SubBulletRow
              as="a"
              href="https://shop.eatyourgrummies.com/account/login"
              rel="noopener noreferrer"
            >
              <SubBulletImg src={SubBullet} aria-hidden /> <LargeP>My account</LargeP>
            </SubBulletRow>
            <BulletRow to="/faq" onClick={closeMenu}>
              <SubBulletImg src={SubBullet} aria-hidden /> <LargeP>FAQ</LargeP>
            </BulletRow>
          </div>
        </div>
        {!hideImg && <BottomImage id="nav-image" src={BottomImg} aria-hidden="true" />}
      </Menu>
    </Drawer>
  )
}

export default NavDrawer
