import React, { useContext } from 'react'
import styled from 'styled-components'

import { P } from './text'

import StoreContext from '../../context/StoreContext'

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  text-align: center;
  border: 1px solid white;
  border-radius: 10px;
  height: 32px;
  width: 86px;
  overflow: hidden;
`

const Button = styled.div`
  height: 100%;
  cursor: pointer;
  padding: 2px 10px 0 10px;
  transition: 200ms ease all;

  &:hover {
    background-color: white;
    color: ${props => props.theme.colors.purple};
  }
`

const QuantityToggle = ({ lineItemId, quantity }: { lineItemId: number | string; quantity: number }) => {
  const { removeVariantFromCart, updateCartLineItem } = useContext(StoreContext)

  const changeQuantity = (adj: number) => {
    const newQuantity = quantity + adj
    if (!newQuantity) removeVariantFromCart(lineItemId)
    else updateCartLineItem(lineItemId, newQuantity)
  }

  return (
    <Container>
      <Button onClick={() => changeQuantity(-1)}>
        <P>-</P>
      </Button>
      <P>{quantity}</P>
      <Button onClick={() => changeQuantity(1)}>
        <P>+</P>
      </Button>
    </Container>
  )
}

export default QuantityToggle
