import React, { useState, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, navigate } from 'gatsby'
import { LineItem, ProductVariant } from 'shopify-buy/index.unoptimized.umd'

import QuantityToggle from '../../shared/QuantityToggle'
import { BasicButton } from '../../shared/buttons'
import { H2, P } from '../../shared/text'
import { media } from '../../../styles/util'
import getProductAttributes from '../../../utils/getProductAttributes'

import StoreContext from '../../../context/StoreContext'

import SwapArrows from '../../../assets/img/icons/swap-arrows'
import CloseX from '../../../assets/img/icons/menu-close.svg'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.02); }
  70% { transform: rotate(-2deg) scale(1.02); }
  100% { transform: rotate(0deg); }
`

const LineItemRow = styled.div`
  border-bottom: 1px solid white;
  box-sizing: content-box;
  display: grid;
  overflow-y: hidden;
  padding: 27px 0 27px 0;
  grid-auto-rows: auto;

  ${media.mobile} {
    width: 98%;
    grid-template-columns: 75px 1fr;
    grid-column-gap: 11px;
  }
  ${media.desktop} {
    width: 97%;
    grid-column-gap: 16px;
    grid-template-columns: 87px 1fr;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  & > img {
    width: 100%;
    height: auto;
    cursor: pointer;
    &:hover {
      animation: ${wiggle} 250ms;
    }
  }
`

const RowGrid = styled.div`
  width: 100%;
  box-sizing: border-box;

  & > div > img {
    height: 10px;
    width: 10px;
    margin-top: 9px;
    cursor: pointer;
    transition: 150ms ease transform;

    &:hover {
      transform: scale(1.3);
    }
  }
`

const FlexRow = styled('div')<{ includesBundle?: boolean }>`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  white-space: ${props => (!props.includesBundle ? 'pre-line' : 'none')};

  &:first-of-type {
    align-items: start;
  }

  ${media.mobile} {
    grid-column-gap: 40px;

    &:first-of-type {
      margin-bottom: 10px;
    }

    & h2 {
      font-size: 22px;
      line-height: 1;

      & span {
        font-size: 12px;
        line-height: 1;
        margin-top: -6px;
      }
    }
  }
  ${media.desktop} {
    grid-column-gap: 16px;

    & h2 {
      font-size: 25px;

      & span {
        font-size: 16px;
        margin-top: -6px;
      }
    }
  }
`

const SwapRow = styled(FlexRow)`
  width: 100%;
  grid-column: span 2;
  padding-top: 10px;
`

const SwapText = styled(P)`
  font-family: Beacon-Bold, sans-serif;
  line-height: 1;

  background: linear-gradient(90deg, #fa6666 0%, #f090c3 39.58%, #52d9bf 73.44%, #21a0f8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  padding: 2px 0 2px 6px;

  ${media.mobile} {
    font-size: 16px;
  }
  ${media.desktop} {
    font-size: 15px;
  }
`

const SwapButton = styled(BasicButton)`
  border: 1px solid white;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: end;
  cursor: pointer;
  outline: none;
  line-height: 1;
  transition: 500ms all ease;

  & svg {
    margin: 0 6px -2px 0;
  }

  & * {
    transition: 500ms all ease;
  }

  &:hover {
    background-color: white;
    color: ${props => props.theme.colors.purple};

    & * {
      fill: ${props => props.theme.colors.purple} !important;
    }
  }

  ${media.mobile} {
    padding: 8px;
    font-size: 16px;
  }
  ${media.desktop} {
    padding: 6px;
    font-size: 14px;
  }
`

const PillWrapper = styled.div`
  width: fit-content;
  height: 20px;
  padding: 2px;
  border-radius: 20px;
  background: linear-gradient(90deg, #fa6666 1.9%, #f090c3 28.98%, #52d9bf 64.74%, #21a0f8 100%);
  display: grid;
  place-items: center;
`

const Pill = styled.div`
  width: fit-content;
  height: 16px;
  background-color: #fff;
  color: ${props => props.theme.colors.purple};
  font-family: BEacon-Bold, sans-serif;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 1;
  border-radius: 20px;
  padding: 0px 5px;
  display: grid;
  place-items: center;
`

const PriceGrid = styled.div`
  display: grid;
  justify-items: end;
  grid-template-rows: minmax(20px, auto) auto;
  grid-row-gap: 4px;
  margin: -10px 0 6px 0;
`

interface ExtendedProductVariant extends ProductVariant {
  sku: string
}

interface AttributeInterface {
  attrs: {
    key: {
      value: string
    }
    value: {
      value: string
    }
  }
}

interface ExtendedLineItem extends LineItem {
  variant: ExtendedProductVariant
  customAttributes: AttributeInterface[]
}

const CartRow = ({
  lineItem,
  includesBundle,
  twoPackCount,
  fourPackCount,
  numberOfItems,
  closeMenu,
}: {
  lineItem: ExtendedLineItem
  includesBundle: boolean
  twoPackCount: number
  fourPackCount: number
  numberOfItems: number
  closeMenu: () => void
}) => {
  const [updating, setUpdating] = useState(false)
  const { products, removeVariantFromCart, swapVariant } = useContext(StoreContext)

  const productLookupKey = products.byVariantId[lineItem.variant.id].basicSku
  const productDetail = products.byProduct[productLookupKey]

  const is1Pack = !lineItem.variant.sku.includes('PK')
  const splitSku = lineItem.variant.sku.split('_')
  const quantity = splitSku[1] ? splitSku[1].split('PK')[0] : '1'
  const quantityKey = quantity === '1' ? 'single' : `multipack_${quantity}`

  const attrs = getProductAttributes(lineItem.customAttributes)
  const isSubscription = Boolean(attrs.selling_plan)

  const totalOtpPrice = Number(lineItem.variant.price) * lineItem.quantity
  const totalSubPrice =
    Number(productDetail[quantityKey].pricing.subscription.price) * lineItem.quantity
  const displayedPrice = isSubscription ? totalSubPrice : totalOtpPrice

  const handleRemove = () => {
    if (!updating) {
      setUpdating(true)
      removeVariantFromCart(lineItem.id)
    }
  }

  const handleSwap = () => {
    if (!updating) {
      setUpdating(true)
      const newVariantId = productDetail.multipack_2.variantId
      swapVariant(lineItem.id, newVariantId, lineItem.quantity)
    }
  }

  const showSwap =
    numberOfItems <= 1 &&
    !includesBundle &&
    !fourPackCount &&
    twoPackCount < 2 &&
    is1Pack &&
    !isSubscription

  const potentialSavings = showSwap
    ? productDetail.single.pricing.oneTime.price * 2 -
      productDetail.multipack_2.pricing.oneTime.price
    : 0

  const swapText = `Save $${potentialSavings} and get free shipping with a 2-pack`

  const maxCharacters = 20

  const titleReflectingSubscription = isSubscription
    ? lineItem.title.split(')').join(' - monthly)')
    : lineItem.title

  const productTitle =
    titleReflectingSubscription.length >= maxCharacters
      ? `${titleReflectingSubscription.split('(').join(`<span>\n(`)}</span>`
      : `${titleReflectingSubscription.split('(').join(`<span>(`)}</span>`

  const productSlug = productDetail?.jsonData?.slug || ''

  return (
    <LineItemRow>
      {lineItem.variant.image ? (
        <img
          src={lineItem.variant.image.attrs.src}
          aria-label="Product detail"
          alt={lineItem.title}
          onClick={() => {
            closeMenu()
            navigate(`/${productSlug}`)
          }}
          style={{ maxHeight: 90 }}
        />
      ) : (
        <div />
      )}
      <RowGrid>
        <FlexRow includesBundle={productDetail.jsonData.bundle}>
          <Link to={`/${productSlug}`} onClick={closeMenu}>
            <H2 dangerouslySetInnerHTML={{ __html: productTitle }} />
          </Link>
          <img src={CloseX} alt="Remove line item" onClick={handleRemove} />
        </FlexRow>
        <FlexRow>
          <QuantityToggle lineItemId={lineItem.id} quantity={lineItem.quantity} />
          <PriceGrid>
            {isSubscription ? (
              <PillWrapper>
                <Pill>Subscribe & Save</Pill>
              </PillWrapper>
            ) : (
              <div />
            )}
            <H2>${displayedPrice.toFixed(2)}</H2>
          </PriceGrid>
        </FlexRow>
      </RowGrid>
      {showSwap && (
        <SwapRow>
          <SwapText>{swapText}</SwapText>
          <SwapButton onClick={handleSwap}>
            <SwapArrows /> Swap
          </SwapButton>
        </SwapRow>
      )}
    </LineItemRow>
  )
}
export default CartRow
