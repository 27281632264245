import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import EmailForm from './EmailForm'
import LegalCopy from './LegalCopy'
import LinkColumnGroup from './LinkColumnGroup'
import RotatingGummy from '../shared/RotatingGummy'
import SocialMediaIcons from './SocialMediaIcons'

import { media } from '../../styles/util'

import BackgroundImage from '../../assets/img/footer/background-mobile.svg'
import FollowUsMobile from '../../assets/img/text-headers/follow-us-mobile.svg'

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;

  ${media.tablet} {
    display: none;
  }
`

const Container = styled.footer`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  width: 100%;

  & p,
  a {
    font-size: 12px;
    color: #fff;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 94px 20px 50px 20px;
`

const LinksGrid = styled.div`
  width: 100%;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 9px;
  justify-content: space-between;

  ${media.mobile} {
    margin-top: 60px;
  }
  ${media.mobile} {
    margin-top: 70px;
  }
`

const Flexbox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 50px 0;
`

const LogoWrapper = styled(Link)`
  height: 101px;
  width: 106px;
`

interface GatsbyImgInterface {
  childImageSharp: {
    fluid: {
      aspectRatio: number
      sizes: string
      src: string
      srcSet: string
    }
  }
}

interface StaticQueryProps {
  backgroundMobile: GatsbyImgInterface
  logo: GatsbyImgInterface
}

interface LinksInterface {
  label: string
  path: string
  external?: boolean
}

interface FooterContentInterface {
  header: string
  links: LinksInterface[]
}

const Footer = ({
  footerContent,
  hideGummy,
}: {
  footerContent: FooterContentInterface[]
  hideGummy: boolean
}) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { regex: "/logo-white.png/" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      const logo = data.logo.childImageSharp.fluid

      return (
        <FooterWrapper>
          {!hideGummy && <RotatingGummy />}
          <br />
          <Container>
            <Content>
              <Flexbox>
                <LogoWrapper to="/">
                  <Img
                    fluid={logo}
                    alt="Grummies logo"
                    style={{ height: '100%', width: '100%' }}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </LogoWrapper>
                <div>
                  <img src={FollowUsMobile} alt="Follow us" />
                  <SocialMediaIcons />
                </div>
              </Flexbox>

              <EmailForm />

              <LinksGrid>
                {footerContent.map((section: FooterContentInterface) => (
                  <LinkColumnGroup
                    header={section.header}
                    links={section.links}
                    key={section.header}
                  />
                ))}
              </LinksGrid>

              <LegalCopy />
            </Content>
          </Container>
        </FooterWrapper>
      )
    }}
  />
)

export default Footer
