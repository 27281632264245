import getProductAttributes from './getProductAttributes'

const containsSubscription = (lineItems: any) =>
  lineItems.some((lineItem: any) => {
    const attrs = getProductAttributes(lineItem.customAttributes)
    return Boolean(attrs.selling_plan)
  })

export default (checkout: any) => {
  let checkoutUrl = ''

  if (checkout && checkout.id) {
    const encodedCheckoutId = encodeURIComponent(checkout.id)
    const shopifyCheckoutUrl = checkout.webUrl.replace(
      'grummies.myshopify.com',
      'shop.eatyourgrummies.com'
    )

    checkoutUrl = containsSubscription(checkout.lineItems)
      ? `https://shop.eatyourgrummies.com/pages/redirect/?checkout_id=${encodedCheckoutId}`
      : shopifyCheckoutUrl
  }

  return checkoutUrl
}
